@media only screen and (min-width: 1920px) {
  .heading {
    &.hd-1 {
      font-size: 4rem;
    }
    &.hd-2 {
      font-size: 8rem;
    }
  }
  .subheading {
    top: 280px;
    font-size: 32px;
  }
  .outer {
    width: 250px;
    height: 250px;

    &.out-1 {
      left: 30px;
    }

    &.out-2 {
      left: 320px;
    }

    &.out-3 {
      left: 610px;
    }

    &.out-4 {
      left: 900px;
    }
  }
  .statement {
    left: 1600px;
    top: 75px;
    font-size: 46px;
  }
  .user-card {
    &.uc-1 {
      left: 1200px;
      width: 22rem;
    }

    &.uc-2 {
      left: 1200px;
      width: 22rem;
    }

    &.uc-3 {
      left: 1575px;
      width: 21rem;
    }
  }
  .vector {
    &.vtr-1 {
      left: 1550px;
    }
    &.vtr-2 {
      top: 430px;
      left: 1550px;
    }

    &.vtr-3 {
      width: 820px;
    }
  }
  .computer-design {
    top: 500px;
    left: 1550px;
    width: 350px;
    height: 250px;
  }
}

@media only screen and (min-width: 1880px) {
  .heading {
    &.hd-1 {
      font-size: 4rem;
    }
    &.hd-2 {
      font-size: 8rem;
    }
  }
  .subheading {
    top: 280px;
    font-size: 32px;
  }
  .outer {
    width: 250px;
    height: 250px;

    &.out-1 {
      left: 30px;
    }

    &.out-2 {
      left: 320px;
    }

    &.out-3 {
      left: 610px;
    }

    &.out-4 {
      left: 900px;
    }
  }
  .statement {
    left: 1600px;
    top: 75px;
    font-size: 46px;
  }
  .user-card {
    &.uc-1 {
      left: 1200px;
      width: 22rem;
    }

    &.uc-2 {
      left: 1200px;
      width: 22rem;
    }

    &.uc-3 {
      left: 1575px;
      width: 18rem;
    }
  }
  .vector {
    &.vtr-1 {
      left: 1550px;
    }
    &.vtr-2 {
      top: 440px;
      left: 1550px;
    }

    &.vtr-3 {
      width: 820px;
    }
  }
  .computer-design {
    top: 510px;
    left: 1530px;
    width: 350px;
    height: 250px;
  }
}

@media only screen and (max-width: 1400px) {
  .heading {
    &.hd-2 {
      font-size: 4.75rem;
    }
  }
  .subheading {
    font-size: 32px;
  }
  .outer {
    width: 170px;
    height: 170px;

    &.out-1 {
      left: 30px;
    }

    &.out-2 {
      left: 220px;
    }

    &.out-3 {
      left: 410px;
    }

    &.out-4 {
      left: 600px;
    }
  }
  .statement {
    left: 1050px;
  }
  .user-card {
    &.uc-1 {
      left: 730px;
      width: 17.5rem;
    }

    &.uc-2 {
      left: 800px;
      width: 13rem;
      top: 21rem;
    }

    &.uc-3 {
      left: 65rem;
      width: 14rem;
    }
  }
  .vector {
    &.vtr-1 {
      left: 1000px;
    }
    &.vtr-2 {
      left: 1000px;
    }

    &.vtr-3 {
      width: 820px;
    }
  }
  .social-media {
    &.icon-1 {
      display: none;
    }
    &.icon-2 {
      display: none;
    }
    &.icon-3 {
      display: none;
    }
  }
  .computer-design {
    left: 1050px;
  }
}

@media only screen and (max-width: 1145px) {
  .social-media {
    display: none;
  }
}

@media only screen and (max-width: 1024px) {
  .outer {
    width: 170px;
    height: 170px;

    &.out-1 {
      left: 30px;
    }

    &.out-2 {
      left: 220px;
    }

    &.out-3 {
      left: 410px;
    }

    &.out-4 {
      left: 600px;
    }
  }
  .statement {
    position: absolute;
    top: 36rem;
    left: 300px;
    transform: rotate(0deg);
  }
  .user-card {
    &.uc-1 {
      top: 42rem;
      left: 1rem;
      width: 15rem;
    }

    &.uc-2 {
      top: 42rem;
      left: 18rem;
      width: 16rem;
    }

    &.uc-3 {
      top: 42rem;
      left: 36rem;
      width: 15rem;
    }
  }
  .vector {
    &.vtr-1,
    &.vtr-2 {
      display: none;
    }

    &.vtr-3 {
      width: 820px;
    }
  }
  .computer-design {
    display: none;
  }
}

@media only screen and (max-width: 840px) {
  .my-menu {
    top: 15% !important;
  }
  .mato_super {
    font-size: 2.6rem;
  }
  .heading {
    &.hd-1 {
      font-size: clamp(1rem, 10vw, 2rem);
      width: 570px;
    }

    &.hd-2 {
      font-size: clamp(1rem, 10vw, 4.6rem);
    }
  }
  .subheading {
    top: 220px;
    font-size: 1.5rem;
  }
  .subsubheading {
    top: 270px;
    font-size: 22px;
  }
  .vector {
    &.vtr-1 {
      display: none;
    }

    &.vtr-2 {
      display: none;
    }
    &.vtr-3 {
      top: 1650px;
      right: 100px;
      height: 200px;
      width: 600px;
    }
  }
  .outer {
    width: 250px;
    height: 250px;

    &.out-1 {
      top: 380px;
      left: 10px;
    }

    &.out-2 {
      top: 350px;
      left: 300px;
    }

    &.out-3 {
      top: 650px;
      left: 10px;
    }

    &.out-4 {
      top: 630px;
      left: 300px;
    }
  }
  .statement {
    top: 920px;
    left: 200px;
  }
  .user-card {
    &.uc-1 {
      top: 65rem;
      left: 5rem;
      width: 30rem;
    }

    &.uc-2 {
      top: 77rem;
      left: 5rem;
      width: 30rem;
    }

    &.uc-3 {
      top: 91.5rem;
      left: 5rem;
      width: 30rem;
    }
  }
}

@media only screen and (max-width: 600px){
  .app-header__logo .logo-src {
    height: 25px;
    width: 130px;
  }
  .heading {
    &.hd-1 {
      width: 300px;
      font-size: 20px;
    }
    &.hd-2 {
      width: 300px;
      top: 115px;
      font-size: 40px;
    }
  }
  .subheading {
    font-size: 17px;
    top: 170px;
  }
  .subsubheading {
    font-size: 14px;
    top: 220px;
  }

  .mato_super {
    font-size: 1.8rem;
  }
  .statement {
    top: 50rem;
    left: 7rem;
    font-size: 36px;
    text-align: center;
    transform: rotate(0deg);
  }
  .vector {
    &.vtr-1 {
      display: none;
    }

    &.vtr-2 {
      display: none;
    }

    &.vtr-3 {
    top: 1600px;
    right: 100px;
    height: 200px;
    width: clamp(360px, 100vw, 420px);
  }
  }
  .outer {
    &.out-1 {
      top: 270px;
      left: 10px;
      width: 150px;
      height: 150px;
    }

    &.out-2 {
      top: 250px;
      left: 170px;
      width: 150px;
      height: 150px;
    }

    &.out-3 {
      top: 440px;
      left: 10px;
      width: 150px;
      height: 150px;
    }

    &.out-4 {
      top: 420px;
      left: 170px;
      width: 150px;
      height: 150px;
    }
  }
  .statement {
    top: 620px;
    left: 75px;
  }
  .user-card {
    &.uc-1 {
      top: 45rem;
      left: 1rem;
      width: 20rem;
    }

    &.uc-2 {
      top: 60rem;
      left: 1rem;
      width: 20rem;
    }

    &.uc-3 {
      top: 77rem;
      left: 1rem;
      width: 20rem;
    }
  }
}

@media only screen and (max-width: 400px){
  .heading {
    &.hd-1 {
      width: 300px;
    }
    &.hd-2 {
      font-size: 35px;
    }
  }
  .subheading {
    font-size: 14.5px;
  }
  .subsubheading {
    font-size: 12px;
  }
  .statement {
    right: 80px;
  }
  .user-card {
    &.uc-1 {
      right: 1rem;
    }

    &.uc-2 {
      right: 1rem;
    }

    &.uc-3 {
      right: 1rem;
    }
  }
}

@media only screen and (max-width: 345px) {
  .app-header__logo .logo-src {
    height: 0;
    width: 0;
  }
  .heading {
    &.hd-1 {
      width: 250px;
      font-size: 15px;
    }
    &.hd-2 {
      top: 100px;
      width: 250px;
      font-size: 25px;
    }
  }
  .subheading {
    top: 130px;
    font-size: 10.5px;
  }
  .subsubheading {
    top: 165px;
    font-size: 9px;
  }
  .outer {
    &.out-1 {
      top: 220px;
      left: 10px;
      width: 125px;
      height: 125px;
    }

    &.out-2 {
      top: 200px;
      left: 140px;
      width: 125px;
      height: 125px;
    }

    &.out-3 {
      top: 360px;
      left: 10px;
      width: 125px;
      height: 125px;
    }

    &.out-4 {
      top: 340px;
      left: 140px;
      width: 125px;
      height: 125px;
    }
  }
  .statement {
    top: 500px;
    left: 80px;
    font-size: 25px;
  }
  .user-card {
    &.uc-1 {
      top: 37rem;
      right: 1.5rem;
      width: 15rem;
    }

    &.uc-2 {
      top: 50rem;
      right: 1.5rem;
      width: 15rem;
    }

    &.uc-3 {
      top: 70rem;
      right: 1.5rem;
      width: 15rem;
    }
  }
}