// Google fonts
@import url("https://fonts.googleapis.com/css2?family=Roboto+Condensed:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&display=swap");

body {
  font-size: 0.8rem;
}

.card {
  border-radius: 18px !important;
  overflow: hidden;
  font-size: 0.7rem;
}

.la-overflow-visible {
  overflow: visible;
}

.card-header {
  border-bottom: 1px solid $la-yellow1;
  background-color: unset;
}

.card-footer {
  border-top: none; //1px solid $la-yellow1;
}

.card-footer-settings {
  padding: 0;
  margin-top: 12px;
  justify-content: flex-end;
}

.la-tablecontainer {
  max-height: 350px;
  min-height: 150px;
  overflow: auto;
}

.card-header,
.card-title {
  color: black;
}

//Examination
.la-examination .card-header{
  color: $la-red3
}

.dropdown-menu-header {
  color: $la-black;
}

.logo-src {
  height: $logo-height;
  width: $logo-width;
  background: url("~assets/svg/logo.svg") no-repeat;
  margin-bottom: 7px;
  margin-right: 13px;
}

.app-main__inner {
  max-width: 1100px;
  width: 100%;
  margin: auto;
}

//Fonts
//$font-family-base:            'Public Sans', sans-serif !default;
// 400 regular
// 700 rubriker
// 900 fat stuff
.app-page-title {
  background: none !important;
  margin: -30px -30px 0px;
}

.app-page-title .page-title-heading {
  font-weight: 900;
  color: black;
  padding: 10px;
  font-size: 1.5rem;
}

.modal-title {
  font-weight: 700;
  color: black !important;
}

.mato_super {
  color: $la-red3;
  font-weight: 900;
  font-size: 4rem;
}

.box-shadow-pink {
  position: relative;
  box-shadow: 0 0 20px $la-red3;
}
.box-shadow-pink::before {
  content: "";
  position: absolute;
  top: -10px;
  right: -10px;
  bottom: -10px;
  left: -10px;
  z-index: -1;
  background-color: $la-red3;
}

.mato_ingress {
  color: $la-red3;
  font-weight: 700;
  font-weight: bolder;
  font-size: 0.88rem;
}

.la.card-title {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
}
.la.card-title select {
  width: auto;
}
.la.card-title a {
  //margin-left: auto;
}

ol,
ul .la-listpadding {
  padding-left: 20px;
  padding-right: 20px;
}

li {
  margin: 3px 0 3px;
}

.app-theme-white .app-page-title {
  //background: $la-yellow3;
  //background: $la-red3;
}

.app-theme-white .app-footer .app-footer__inner,
.app-theme-white .app-header {
  //background: $la-yellow3;
  //background: $la-red3;
}

.app-theme-white.app-container {
  //background: $la-yellow2;
  //background: $la-red4;
}

a.la {
  color: $la-red3 !important;
}
.ReactTable a.la {
  margin-left: 10px;
}
a:hover {
  color: $la-red2 !important;
  text-decoration: none;
}

// Settings

.la-settingHeader {
  display: flex;
  align-items: center;
}
.la-settingHeader a {
  margin-left: auto;
}

#subjectFilter {
  max-width: 150px;
}

.bg-success {
  background: $la-yellow2 !important;
}
.border-success {
  border-color: $la-yellow1 !important;
}

.btn-success {
  color: #fff;
  background-color: $la-red3;
  border-color: $la-red3;
}

.btn-success:hover {
  background-color: $la-red2;
  border-color: $la-red1;
}

.btn-success:focus,
.btn-success:active {
  background-color: $la-red1 !important;
  border-color: $la-red1 !important;
}

.btn-focus:hover {
  background-color: $la-red2;
  border-color: $la-red1;
}

.btn-link {
  color: $la-red2 !important;
}
.btn-link:hover {
  color: $la-red1 !important;
  text-decoration: none;
}

.noAvatar {
  color: $la-red3;
  margin-top: 6px;
}
.avatar-icon {
  border: $la-red3 solid 3px;
}

/*.was-validated .form-control:valid, .form-control.is-valid {
	border-color: $la-yellow1;
}*/

// Sidebar and logo:
// Size in _layout_variables
// List item size, colour and spacing in _navmenu-vertical.scss, and in _variables.scss
.app-sidebar__heading {
  color: $la-grey-40; //$la-red3;
  font-size: 0.7rem;
  margin: 1.3rem 0rem 0.3rem;
}

.app-sidebar .app-sidebar__inner {
  padding: 20px 0.8rem 0.8rem;
}

.metismenu-link {
  border-radius: 18px !important;
  //width: max-content;
}

// Widget list
.widget-heading {
  color: black;
}

.list-group-item {
  padding: 0.4rem 0;
  margin-top: 0;
}

// Teatcher profile
.la-profilepic {
  width: 100%;
  height: 200px;
  object-fit: contain;
}

.la-profilepic-small {
  width: 100%;
  height: 200px;
  object-fit: cover;
}

//  padding-bottom: 15px;

// FORM assignment
.la-progressbar-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.la-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.la-margin {
  margin-left: 10px;
}

//Subscription admin
.la-wide-modal.modal-dialog {
  max-width: 800px;
}

.la-subListHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.la-listHeader {
  font-weight: bold;
  margin-bottom: 10px;
}

//CKEDITOR
//Should be same as mato_super
h1 {
  color: $la-red3;
  font-weight: 900;
  font-size: 4rem;
}

// Should be same as card title
h2 {
  color: black;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 0.88rem;
}

//Should be same as mato_ingress
h3 {
  color: $la-red3;
  font-weight: bolder;
  font-size: 0.88rem;
}

//error text
.la-errormsg {
  color: $la-red1;
}

//CookieConsent
.CookieConsent {
  background-color: $la-grey-80 !important;
}

.CookieConsent #rcc-confirm-button {
  background-color: $la-red1 !important;
  color: $la-white !important;
}

.la-more-dropdown {
  background-color: $la-white;
  border-color: $la-white;
  padding: 0;
  vertical-align: top;
}
.la-more-dropdown:hover {
  background-color: $la-white;
  border-color: $la-white;
  color: $la-red3;
}
.la-more-dropdown-body {
  font-size: 0.7rem;
}

.rotate-90 {
  display: inline-block;
  transform: rotate(90deg);
}

.la-skolon-button-wrapper {
  margin-top: 4px;
  margin-bottom: 1px;
  margin-right: 5px;
}

.la-cms-from-salesforce {
  p {
    margin-top: 0;
    margin-bottom: 0;
  }

  img {
    max-width: 100%;
    height: auto;
    margin-bottom: var(--lwc-spacingSmall,0.75rem);
  }

  h2 {
    color: rgb(240, 132, 138);
  }

  a {
    color: #f0848a !important;
  }
  
  a:hover {
    color: #eb5b63 !important;
    text-decoration: none;
  }
}

.la-linktext {
  text-transform: none;
  font-weight: normal;
}

.la-greyedOutListItem {
  //color: #DDD;
  color: #DDD;
  border-color: #DDD;
}

@media only screen and (max-device-width : 1024px) {
  .mato_super {
    font-size: 2.3rem;
  }
}

@media only screen and (max-width: 360px){
  .app-header__logo .logo-src {
    height: 25px;
    width: 130px;
  }
  .mato_super {
    font-size: 1.8rem;
  }
}

@media only screen and (max-width: 280px){
  .app-header__logo .logo-src {
    height: 0;
    width: 0;
  }
}
