// General

a,
button,
.btn {
  outline: none !important;
}

.app-container {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  margin: 0;

  @media only screen and (max-width: 1400px) {
    min-height: 150vh;
  }

  @media only screen and (max-width: 700px) {
    min-height: 165vh;
  }

  @media only screen and (max-width: 600px) {
    min-height: 220vh;
  }
}