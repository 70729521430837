.heading {
  &.hd-mp-1 {
    top: 75px;
    font-size: clamp(2.3rem, 5vw, 4.6rem);
  }
}

.staff-card {
  font-size: 18px;

  &.title {
    color: #F0848A;
  }
}

.ul-arrow {
  list-style: none;
}

.li-arrow::before {
  content: '▶';
  left: 0;
}

.login-modal {
  padding-right: 250px;
}