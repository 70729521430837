@media only screen and (max-width: 1425px) {
  .heading {
    &.hd-mp-1 {
      top: 100px;
    }
  }
}

@media only screen and (max-width: 1130px) {
  .login-modal {
    padding-right: 0;
  }
}