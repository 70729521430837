.heading {

  &.hd-st-1 {
    top: 100px;
    left: 250px;
    width: 800px;
    height: 200px;
    font-size: 18px;
  }

  &.hd-st-2 {
    top: 120px;
    left: 250px;
    font-size: 4.5rem;
  }
}

.text-body {
  position: absolute;
  top: 220px;
  left: 250px;
  width: 700px;
}

.outer-st {
  position: absolute;
  width: 200px;
  height: 200px;
  border-radius: 50%;
  background-color: #EE959E;
  margin: 10px;
  cursor: pointer;

  &.out-st-1 {
    top: 70px;
    left: 960px;
  }

  &.out-st-2 {
    top: 140px;
    left: 1105px;
  }

  &.out-st-3 {
    top: 70px;
    left: 1250px;
  }

  &:hover .inner {
    width: 90%;
    height: 90%;
  }
}

.font-st {
  font-weight: bold;
  color: #000000;
}


.footer-st {
  position: absolute;
  width: 1450px;
  height: 200px;
  left: 0;
  top: 700px;
  background: #D9D9D9;
  z-index: -100;
}

.vector {

  &.vtr-st {
    width: 1450px;
    height: 150px;
    left: 0;
    top: 570px;
    overflow: hidden;
    background: url('~assets/utils/svg/bottom-wave.svg');
  }

}