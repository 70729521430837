.heading {
  position: absolute;
  font-weight: 500;
  color: #000000;

  &.hd-1 {
    top: 4.7rem;
    left: 1.5rem;
    width: 800px;
    height: 200px;
    font-size: 36px;
  }

  &.hd-2 {
    top: 8rem;
    left: 1.5rem;
    font-size: 5rem;

  }
}

.subheading {
  position: absolute;
  left: 25px;
  top: 240px;
  font-style: normal;
  font-weight: 700;
  font-size: 37px;
  line-height: 45px;
  /* identical to box height */
  color: #F0848A;
}

.subsubheading {
  position: absolute;
  left: 25px;
  top: 290px;
  font-style: italic;
  font-weight: 500;
  font-size: 27px;
  line-height: 35px;
  color: #F0848A;
}

.statement {
  font-family: 'Dancing Script', serif;
  position: absolute;
  left: 1200px;
  top: 70px;
  font-style: normal;
  font-weight: 700;
  font-size: 36px;
  line-height: 39px;
  text-align: center;
  color: #F0848A;
  transform: rotate(11.35deg);
}


.computer-design {
  position: absolute;
  width: 200px;
  height: 150px;
  left: 1200px;
  top: 530px;
}

.outer {
  position: absolute;
  width: 200px;
  height: 200px;
  border-radius: 50%;
  background-color: #EE959E;
  margin: 10px;
  cursor: pointer;

  &.out-1 {
    top: 350px;
    left: 10px;
  }

  &.out-2 {
    top: 380px;
    left: 230px;
  }

  &.out-3 {
    top: 350px;
    left: 450px;
  }

  &.out-4 {
    top: 380px;
    left: 670px;
  }

  &:hover .inner {
    width: 90%;
    height: 90%;
  }
}

.inner {
  position: absolute;
  width: 85%;
  height: 85%;
  border-radius: 50%;
  text-align: center;
  font-size: 12px;
  line-height: 1.42857;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #FFB6C1;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: all 0.5s ease-out;
}

.vector {
  position: absolute;

  &.vtr-1 {
    top: 150px;
    width: 73px;
    height: 48px;
    left: 1150px;
    border: none;
    border-top: 2px dashed #F0848A;
    transform: rotate(45deg);
  }

  &.vtr-2 {
    width: 73px;
    height: 50px;
    left: 1150px;
    top: 490px;
    border: none;
    border-top: 2px dashed #F0848A;
    transform: rotate(135deg);
  }
}

.user-card {
  position: absolute;

  &.uc-1 {
    width: 18rem;
    top: 7em;
    left: 880px;
  }
  &.uc-2 {
    width: 15rem;
    top: 20rem;
    left: 930px;
  }
  &.uc-3 {
    width: 14rem;
    top: 12rem;
    left: 75rem;
  }
}

.social-media {
  font-size: 25px;
  color: #EE959E;

  &.icon-1 {
    position: absolute;
    top: 18px;
    left: 1150px;
  }
  &.icon-2 {
    position: absolute;
    top: 18px;
    left: 1180px;
  }
  &.icon-3 {
    position: absolute;
    top: 18px;
    left: 1210px;
  }

  &:hover {
    color: $la-red2;
  }
}
