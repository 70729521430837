@media only screen and (max-width: 1920px) {
  .heading {
    &.hd-st-1 {
      font-size: 3rem;
    }
    &.hd-st-2 {
      top: 150px;
      font-size: 5.75rem;
    }
  }

  .text-body {
    position: absolute;
    top: 280px;
    left: 250px;
    width: 900px;
  }

  .outer-st {
    width: 240px;
    height: 240px;

    &.out-st-1 {
      top: 70px;
      left: 1150px;
    }
    &.out-st-2 {
      top: 140px;
      left: 1400px;
    }
    &.out-st-3 {
      top: 70px;
      left: 1650px;
    }
  }
  .footer-btn-st {
    width: 175px;
    height: 50px;

    &.btn-st-1 {
      left: 240px;
    }

    &.btn-st-2 {
      left: 450px
    }

    &.btn-st-3 {
      left: 660px;
    }

    &.btn-st-4 {
      left: 870px;
    }

    &.btn-st-5 {
      left: 1080px;
    }
  }
  .footer-st {
    width: 1413px;
  }
  .vector {
    &.vtr-st {
      width: 1413px;
    }
  }
}

@media only screen and (max-width: 1445px) {
  .heading {
    &.hd-st-1 {
      top: 100px;
      left: 250px;
      width: 800px;
      height: 200px;
      font-size: 18px;
    }

    &.hd-st-2 {
      top: 120px;
      left: 250px;
      font-size: 4.5rem;
    }
  }

  .text-body {
    top: 220px;
    left: 250px;
    width: 700px;
  }
  .outer-st {
    height: 150px;
    width: 150px;

    &.out-st-1 {
      top: 70px;
      left: 960px;
    }

    &.out-st-2 {
      top: 140px;
      left: 1105px;
    }

    &.out-st-3 {
      top: 70px;
      left: 1250px;
    }
  }
  }

@media only screen and (max-width: 1413px) {
  .outer-st {

    &.out-st-1 {
      top: 70px;
      left: 960px;
    }
    &.out-st-2 {
      top: 240px;
      left: 1000px;
    }
    &.out-st-3 {
      top: 420px;
      left: 960px;
    }
  }
}

@media only screen and (max-width: 1249px) {
  .heading {
    &.hd-st-1 {
      left: 100px;
    }

    &.hd-st-2 {
      left: 100px;
    }
  }
  .text-body {
    left: 100px;
    width: 660px;
  }
  .outer-st {
    &.out-st-1 {
      left: 880px;
    }
    &.out-st-2 {
      left: 920px;
    }
    &.out-st-3 {
      left: 880px;
    }
  }
  .footer-btn-st {

    &.btn-st-1 {
      left: 100px;
    }

    &.btn-st-2 {
      left: 300px
    }

    &.btn-st-3 {
      left: 500px;
    }

    &.btn-st-4 {
      left: 700px;
    }

    &.btn-st-5 {
      left: 900px;
    }
  }
  .footer-st {
    width: 1248px;
  }
  .vector {
    &.vtr-st {
      width: 1248px;
    }
  }
}

@media only screen and (max-width: 1085px) {
  .text-body {
    font-size: 0.75rem;
  }
  .outer-st {
    &.out-st-1 {
      top: 35rem;
      left: 100px;
    }

    &.out-st-2 {
      top: 37rem;
      left: 275px;
    }

    &.out-st-3 {
      top: 35rem;
      left: 450px;
    }
  }
  .footer-btn-st {

    &.btn-st-1 {
      top: 820px;
      left: 100px;
    }

    &.btn-st-2 {
      top: 820px;
      left: 300px
    }

    &.btn-st-3 {
      top: 820px;
      left: 500px;
    }

    &.btn-st-4 {
      top: 900px;
      left: 300px;
    }

    &.btn-st-5 {
      top: 900px;
      left: 500px;
    }
  }
  .footer-st {
    top: 850px;
    width: 1084px;
  }
  .vector {
    &.vtr-st {
      top: 750px;
      width: 1084px;
    }
  }
}

@media only screen and (max-width: 991px) {
  .heading {
    &.hd-st-1 {
      width: 300px;
      left: 50px;
    }

    &.hd-st-2 {
      left: 50px;
    }
  }
  .text-body {
    left: 50px;
  }
  .outer-st {
    &.out-st-1 {
      top: 35rem;
      left: 75px;
    }

    &.out-st-2 {
      top: 37rem;
      left: 250px;
    }

    &.out-st-3 {
      top: 35rem;
      left: 425px;
    }
  }

}

@media only screen and (max-width: 740px) {
  .heading {
    &.hd-st-1 {
      left: 60px;
    }

    &.hd-st-2 {
      top: 135px;
      left: 60px;
      font-size: 2.8rem;
    }
  }
  .text-body {
    font-size: 0.70rem;
    width: 400px;
  }
  .outer-st {
    &.out-st-1 {
      top: 42rem;
      left: 75px;
    }

    &.out-st-2 {
      top: 48rem;
      left: 250px;
    }

    &.out-st-3 {
      top: 53rem;
      left: 75px;
    }
  }
  .footer-btn-st {
    top: 900px;

    &.btn-st-1 {
      top: 1100px;
      height: 60px;
      width: 400px;
      left: 50px;
    }

    &.btn-st-2 {
      top: 1200px;
      height: 60px;
      width: 400px;
      left: 50px;
    }

    &.btn-st-3 {
      top: 1300px;
      height: 60px;
      width: 400px;
      left: 50px;
    }

    &.btn-st-4 {
      top: 1400px;
      height: 60px;
      width: 400px;
      left: 50px;
    }

    &.btn-st-5 {
      top: 1500px;
      height: 60px;
      width: 400px;
      left: 50px;
    }
  }
  .footer-st {
    top: 1100px;
    width: 700px;
    height: 700px;
  }
  .vector {
    &.vtr-st {
      top: 980px;
      right: 100px;
      height: 200px;
      width: 700px;
    }
  }
}

@media only screen and (max-width: 600px) {
  .heading {
    &.hd-st-1 {
      left: 60px;
    }

    &.hd-st-2 {
      top: 135px;
      left: 60px;
      font-size: 2.8rem;
    }
  }

  .footer-st {
    width: 600px;
  }
  .vector {
    &.vtr-st {
      width: 600px;
    }
  }
}

@media only screen and (max-width: 490px) {
  .heading {
    width: 200px;

    &.hd-st-1 {
      left: 20px;
    }

    &.hd-st-2 {
      left: 20px;
      font-size: 2rem;
    }
  }
  .text-body {
    width: 365px;
    left: 10px;
  }
  .outer-st {
    &.out-st-1 {
      left: 10px;
    }

    &.out-st-2 {
      left: 170px;
    }

    &.out-st-3 {
      left: 10px;
    }
  }
}

@media only screen and (max-width: 360px) {
  .heading {

    &.hd-st-1 {
      width: 100px;
    }

    &.hd-st-2 {
      font-size: 1.5rem;
    }
  }
  .text-body {
    width: 270px;
  }
  .outer-st {

    &.out-st-1 {
      top: 51rem;
      height: 125px;
      width: 125px;
    }

    &.out-st-2 {
      top: 55rem;
      left: 140px;
      height: 125px;
      width: 125px;
    }

    &.out-st-3 {
      top: 60rem;
      height: 125px;
      width: 125px;
    }
  }
}